import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import GBIButton from '../../util/buttons/components/GBIButton';
import './gbi-forgotten-password.scss';
import { validateEmail } from '../../util/email';
import LoadingIcon from '../../util/icons/components/LoadingIcon';
import AdminAPI from '../AdminAPI';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const ForgottenPassword = ({ location }) => {
  const [ error, setError ] = useState(null);
  const [ showButton, setShowButton ] = useState(true);
  const [ showLoading, setShowLoading ] = useState(false);
  const [ result, setResult ] = useState(null);
  const [ showError, setShowError ] = useState(false);
  const sendRequest = () => {
    const email = emailRef.current.value.toLowerCase();
    if (validateEmail(email) !== true) {
      setError('please enter a valid email address');
      setShowError(true);
    } else {
      setError(null);
      setShowButton(false);
      setShowLoading(true);
      AdminAPI.requestPasswordReset(email.toLowerCase()).then(resp => {
        if (resp.data && resp.data.email && resp.data.messageSent) {
          const msg = `We've sent an email to ${resp.data.email} with 
            further instructions`;
          setResult(
            <p className="result success">
              { msg }
            </p>
          );
        } else {
          setResult(
            <p className="result error">
              { 'Error: unexpected API response' }
            </p>
          );
        }
        setShowLoading(false);
      }).catch(error => {
        setResult(
          <p className="result error">
            { `Error: ${error.toString()}` }
          </p>
        );
        setShowLoading(false);
      });
    }
  };
  const emailRef = useRef();
  let wrapperClass = 'input-wrapper';
  let errorElement = error && showError ? (
    <div className="error-feedback">
      { error }
    </div>
  ) : null;
  const handleEmailChange = () => {
    const value = emailRef.current.value;
    if (validateEmail(value) !== true) {
      setError('please enter a valid email address');
    } else {
      setError(null);
    }
  };
  const button = showButton ? (
    <GBIButton className={ error && showError ? 'disabled' : '' }
      label="Reset" onClick={ sendRequest } />
  ) : null;
  const loading = showLoading ? <LoadingIcon /> : null;
  const meta = `Can't remember your GoodBeef Index password?
    Enter your details here and we'll help you set a new one.`;
  const canon = `${window.location.origin}/${location.pathname}`;
  return (
    <div id="gbi-forgotten-pwd-page">
      <Helmet>
        <title>Reset your GoodBeef Index password</title>
        <meta name="description" content={ meta } />
        <link rel="canonical"
          href={ canon } />
      </Helmet>
      <h1 className="title">Reset your password</h1>
      <form className="inputs">
        <label htmlFor="name">Enter your email address</label>
        <div className={ wrapperClass }>
          <input ref={ emailRef }
            onBlur={ () => setShowError(true) }
            onChange={ handleEmailChange } />
          { errorElement }
        </div>
        { result }
        <div className="button-row right">
          <Link className="login-link" to="/login">Login</Link>
          { button }
          { loading }
        </div>
      </form>
    </div>
  );
};

ForgottenPassword.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
};

export default ForgottenPassword;
